import { useState, FormEvent, ChangeEvent, useEffect, useContext } from 'react'
import './index.css'
import Header from "../../components/Header"
import Footer from '../../components/Footer'
import { Link, useParams } from 'react-router-dom'
import { IoMdArrowBack } from 'react-icons/io'
import { AiOutlineUpload } from 'react-icons/ai'
import { BsCheckLg } from 'react-icons/bs'
import { Input, TextArea } from '../../components/Input'
import { Button } from '../../components/Button'
import { toast } from 'react-toastify'
import { setupApiClient } from '../../services/api'
import { useNavigate } from 'react-router-dom'
import Loading from '../../components/Loading'
import { CategoriaProps } from '../Categoria'
import { AuthContext } from '../../contexts/AuthContext'
import { MdCancel } from 'react-icons/md'

export default function NovoProduto(){
    const { user } = useContext(AuthContext)
    const navigate = useNavigate()
    const { id } = useParams()

    const [listCategoria, setListCategoria] = useState<CategoriaProps[]>([])
    const [loading, setLoading] = useState(false)
    const [categSelecionada, setCategSelecionada] = useState(0)
    const [imagem, setImagem] = useState(null)
    const [imagemUrl, setImagenUrl] = useState('')
    const [banner_img, setBannerImg] = useState('')
    const [nomeProduto, setNomeProduto] = useState('')
    const [preco, setPreco] = useState('')
    const [custo, setCusto] = useState('')
    const [estoque, setEstoque] = useState('')
    const [ncm, setNCM] = useState('')
    const [cst, setCST] = useState('')
    const [cfop, setCFOP] = useState('')
    const [i_cardapio, setCardapio] = useState('S')
    const [descricao, setDescricao] = useState('')

    useEffect( () => {

        async function getCategorias(){
            try{
                const apiClient = setupApiClient()
                const response = await apiClient.get('/categorias',{
                    params:{
                        empresa: user?.id
                    }
                })

                setListCategoria(response.data)
                setLoading(false)
            } catch{
                setListCategoria([])
                setLoading(false)
            }
        }

        getCategorias()
    },[])

    useEffect( () => {
       
        async function getDetail(){
            if(id){
                try{
                    setLoading(true)
                    const apiClient = setupApiClient();
                    const response = await apiClient.get('/produto',{
                        params:{
                            id: id,
                            empresa: sessionStorage.getItem('@id_meu_bar') 
                        }
                    })
        
                    const { NOMEPRODUTO, PRECO, DESCRICAO, URL, BANNER, CODCATEGORIA, CUSTO,
                            ESTOQUE, CST, CFOP, NCM, VISUALIZARCARDAPIO } = response.data

                    setImagenUrl(URL)
                    setNomeProduto(NOMEPRODUTO)
                    setPreco(PRECO)
                    setDescricao(DESCRICAO)
                    setBannerImg(BANNER)
                    setEstoque(ESTOQUE)
                    setCusto(CUSTO)
                    setCST(CST)
                    setCFOP(CFOP)
                    setNCM(NCM)
                    setCardapio(VISUALIZARCARDAPIO)

                    const index = listCategoria.findIndex(item => item.ID === CODCATEGORIA)
                    setCategSelecionada(index)
                    setLoading(false)
                } catch {
                    navigate('/produtos')
                    toast.warn('Erro tentando buscar o produto!')
                    setLoading(false)
                }
            }
        }

        getDetail() 

    },[listCategoria])

    async function handleSalvar(e: FormEvent){
        e.preventDefault();
        
        if (listCategoria.length === 0){
            toast.warn('Não existe categoria cadastrada!')
            return
        }

        if (nomeProduto === '' || preco === ''){
            toast.warn('Preencha os campos para cadastrar!')
            return;
        }

        if(id){
            try{
                const req = new FormData();
                req.append('empresa', user?.id)
                req.append('id_categoria', listCategoria[categSelecionada].ID)
                req.append('nomeproduto',nomeProduto.toUpperCase());
                req.append('preco',preco == '' ? '0' : preco);
                req.append('descricao', descricao == '' ? '' : descricao.toUpperCase());
                req.append('custo', custo == '' ? '0' : custo)
                req.append('estoque',estoque == '' ? '0' : estoque)
                req.append('cst', cst)
                req.append('ncm', ncm)
                req.append('cfop', cfop == '' ? '0' : cfop)
                req.append('cardapio', i_cardapio)


                if (imagem){
                    req.append('file', imagem)
                }

                req.append('id_produto', id)

                const apiClient = setupApiClient();
    
                const response = await apiClient.put('/produto', req );

                if(response.data?.COD == 200){
                    toast.success('Produto atualizado com sucesso!')
                    navigate('/produtos')
                    setLoading(false)
                } else {
                    setLoading(false)
                    toast.error(response.data?.COD+' - '+response.data?.MSG)
                    return 
                }
   
            } catch(error) {
                console.log(error)
                toast.error('Ops, erro atualizando o produto!')
            }

        } else {
            try{
                setLoading(true)
                const req = new FormData();
                req.append('empresa', user?.id)
                req.append('id_categoria', listCategoria[categSelecionada].ID)
                req.append('nomeproduto',nomeProduto.toUpperCase());
                req.append('preco',preco);
                req.append('descricao', descricao == '' ? '' : descricao.toUpperCase());
                req.append('custo', custo == '' ? '0' : custo)
                req.append('estoque',estoque == '' ? '0' : estoque)
                req.append('cst', cst)
                req.append('ncm', ncm)
                req.append('cfop', cfop == '' ? '0' : cfop)
                req.append('cardapio', i_cardapio)

                if(imagem){
                    req.append('file', imagem)
                }
    
                const apiClient = setupApiClient();
    
                const response = await apiClient.post('/produto', req )

                if(response.data?.COD == 201){
                    toast.success('Produto cadastrado com sucesso!')
                    navigate('/produtos')
                    setLoading(false)
                } else {
                    setLoading(false)
                    toast.error(response.data?.COD+' - '+response.data?.MSG)
                    return 
                }
    
            } catch(error) {
                console.log(error)
                setLoading(false)
                toast.error('Ops, erro cadastrando o produto!')
            }
        }
    }

    function handleFile(e: ChangeEvent<HTMLInputElement>){
        if (!e.target.files){
            return;
        }

        const img = e.target.files[0];

        if (!img){
            return;
        }

        const imgSize = img.size / 1024;

        if(imgSize > 500){
            toast.error('Ops, tamanho da imagem acima de 500kb, reduza o tamanho!')
            return
        }

        if (img.type === 'image/jpeg' || img.type === 'image/png' || img.type === 'image/jpg'){
            setImagem(img)
            setImagenUrl(URL.createObjectURL(img))
        } else {
            toast.error('Imagens suportadas, JPEG, PNG, JPG!')
            return            
        }
    }

    function handleSelected(e: any){
        setCategSelecionada(e.target.value)
    }

    if(loading){
        return(
            <main>
                <Header
                    menu={3}
                />
                <Loading/>
                <Footer/>
            </main>
        )
    }

    return(
        <main>
            <Header
                menu={3}
            />
            <section className='containerNovoProd'>
                <div className='div-container-novoprod'>
                    <div className='div-btnback'>
                        <Link title='VOLTAR' to='/produtos'>
                            <IoMdArrowBack size={30} color='#FFF'/>
                        </Link>
                        <h4>{ id ? 'Editando produto' : 'Novo Produto'}</h4>
                    </div>

                    <form className='container-form' onSubmit={handleSalvar}>
                        <div className='div-foto'>
                            <p>Para melhor visualização da foto usar dimensões de 420 x 420px</p>
                            <label>
                                <span>
                                    <AiOutlineUpload size={30} color="#FFF"/>
                                </span>
                                <input type='file' accept="image/png, image/jpeg, image/jpg" onChange={handleFile}/>

                                { imagemUrl && (
                                    <img className='preview' src={imagemUrl} alt="Foto do produto" width={500} height={240}/>
                                )}
                            </label>
                        </div>
                    
                        <div className='linha'>
                            <div className='camposprod'>
                                <label htmlFor="id-cardapio">CARDÁPIO</label>
                                <select id='id-cardapio' value={i_cardapio} onChange={(e) => setCardapio(e.target.value)}>
                                    <option value="S">SIM</option>
                                    <option value="N">NÃO</option>
                                </select> 
                            </div>

                            <div className='camposprod'>
                                <label htmlFor="id-categoria">CATEGORIA</label>
                                <select id='id-categoria' value={categSelecionada} onChange={handleSelected}>
                                { listCategoria.map( (item, index) => {
                                    return(
                                        <option key={item?.ID} value={index}>
                                            {item?.CATEGORIA}
                                        </option>
                                    )
                                })}
                                </select> 
                            </div>

                            <div className='camposprod'>
                                <label htmlFor="tituloprod">TÍTULO DO PRODUTO</label>
                                <Input
                                    id='tituloprod'
                                    type='text'
                                    placeholder="Digite nome do produto"
                                    value={nomeProduto}
                                    onChange={ (e) => setNomeProduto(e.target.value)}
                                />
                            </div>
                        </div>                    
                        <div className='linha'>
                            <div className='camposprod'>
                                <label htmlFor="ip-estoque">ESTOQUE</label>
                                <Input
                                    style={{width:200}}
                                    id='ip-estoque'
                                    type='text'
                                    placeholder="Estoque"
                                    value={estoque}
                                    onChange={ (e) => setEstoque(e.target.value)}
                                />
                            </div>
                            <div className='camposprod'>
                                <label htmlFor="ip-custo">VALOR DE CUSTO</label>
                                <Input
                                    style={{width:200}}
                                    id='ip-custo'
                                    type='text'
                                    placeholder="Custo do produto"
                                    value={custo}
                                    onChange={ (e) => setCusto(e.target.value)}
                                />
                            </div>
                            <div className='camposprod'>
                                <label htmlFor="ip-valor">VALOR DE VENDA</label>
                                <Input
                                    style={{width:200}}
                                    id='ip-valor'
                                    type='text'
                                    placeholder="Valor do produto"
                                    value={preco}
                                    onChange={ (e) => setPreco(e.target.value)}
                                />
                            </div>
                        </div>                    
                        <div className='linha'>
                            <div className='camposprod'>
                                <label htmlFor="ip-ncm">NCM</label>
                                <Input
                                    style={{width:200}}
                                    id='ip-ncm'
                                    type='text'
                                    placeholder="NCM"
                                    value={ncm}
                                    onChange={ (e) => setNCM(e.target.value)}
                                />
                            </div>
                            <div className='camposprod'>
                                <label htmlFor="ip-cst">CST</label>
                                <Input
                                    style={{width:200}}
                                    id='ip-cst'
                                    type='text'
                                    placeholder="CST do produto"
                                    value={cst}
                                    onChange={ (e) => setCST(e.target.value)}
                                />
                            </div>
                            <div className='camposprod'>
                                <label htmlFor="ip-cfop">CFOP</label>
                                <Input
                                    style={{width:200}}
                                    id='ip-cfop'
                                    type='text'
                                    placeholder="CFOP do produto"
                                    value={cfop}
                                    onChange={ (e) => setCFOP(e.target.value)}
                                />
                            </div>
                        </div>                    
                        <div className='div-textarea'>
                            <TextArea
                                placeholder="Descrição de ingredientes..."
                                value={descricao}
                                onChange={ (e) => setDescricao(e.target.value)}
                            />          
                        </div>

                        <div className='div-btn-salvarprod'>
                            <Button type='submit'>
                                <BsCheckLg color='#FFF' />
                                SALVAR
                            </Button> 
                            <Link title='VOLTAR' to='/produtos'>
                                <MdCancel size={30} color='#fff'/>CANCELAR
                            </Link>  
                        </div>
                    </form>
                </div>
            </section>

            <Footer/>
        </main>
    )
}