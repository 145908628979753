import Header from "../../components/Header"
import Footer from "../../components/Footer"
import './index.css'


export default function Dashboard(){

    return(
        <main>
            <Header
                menu={0}
            />

            <section className='containerDashboard'>
                <div className='div-content-dash'>
                    <h3>Dashboard</h3>
                </div>
            </section>

            <Footer/>
        </main>
    )
}