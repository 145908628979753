import { useContext } from 'react'
import './index.css'
import { useNavigate } from 'react-router-dom'
import { Link } from 'react-router-dom'
import logoImg from '../../assets/logo.svg'
import { BiFoodMenu, BiCategory } from 'react-icons/bi'
import { MdBorderColor } from 'react-icons/md'
import { FiLogOut } from 'react-icons/fi'
import { GiRoundTable, GiMoneyStack } from 'react-icons/gi'
import { toast } from 'react-toastify'
import { AuthContext } from '../../contexts/AuthContext'

interface MenuProps{
    menu: number;
}

export default function Header({ menu }: MenuProps){
    const { user, signOut, } = useContext(AuthContext)
    const navigate = useNavigate()

    function handleSair(){
        try{
            signOut()
            navigate('/')
            toast.info('Até logo... :)')
        } catch{
            
        }
    }

    return(
        <header className='container'>
            <section className='content'>       
                <div className='logo'>
                    <Link to='/dashboard'>
                        <img src={logoImg} alt='Logo MeuBar' width={200}/>    
                    </Link>
                    <span>{user?.name} - <Link to='/account'>MINHA CONTA</Link></span>
                </div>            
            
                <nav className='menu'>
                    <Link 
                        to='/pedidos' 
                        style={ { borderBottom: menu == 1 && "2px solid #2ECC71"} }
                    >
                        <MdBorderColor size={20} color='#F21A22'/>
                        PEDIDOS
                    </Link>

                    <Link 
                        to='/categoria' 
                        style={ { borderBottom: menu == 2 && "2px solid #2ECC71"} }
                    >
                        <BiCategory size={20} color='#F21A22'/>
                        CATEGORIA
                    </Link>      

                    <Link 
                        to='/produtos' 
                        style={ { borderBottom: menu == 3 && "2px solid #2ECC71"} }
                    >
                        <BiFoodMenu size={20} color='#F21A22'/>
                        CARDÁPIO
                    </Link>   

                    <Link 
                        to='/mesas' 
                        style={ { borderBottom: menu == 4 && "2px solid #2ECC71"} }
                    >
                        <GiRoundTable size={20} color='#F21A22'/>
                        MESAS
                    </Link>   

                    <Link 
                        to='/caixa' 
                        style={ { borderBottom: menu == 5 && "2px solid #2ECC71"} }
                    >
                        <GiMoneyStack size={20} color='#F21A22'/>
                        CAIXA
                    </Link>   

                    <button className='sair' onClick={handleSair}>
                        <FiLogOut size={20} color='#F21A22'/>
                        SAIR
                    </button>           
                </nav>
            </section> 
        </header>
    )
}