import { useState, useContext } from 'react'
import './index.css'
import { FaWindowClose } from 'react-icons/fa'
import { BiTransfer } from "react-icons/bi"
import { toast } from 'react-toastify'
import { isNumber } from '../../utils/utils'
import { MdRefresh } from "react-icons/md"
import { setupApiClient } from '../../services/api'
import { AuthContext } from '../../contexts/AuthContext'


interface modalTrasnfProps{
    mesas: any[]
    onClose: () => void
    onRefresh: () => void
}

export default function ModalTransferMesa({ mesas, onClose, onRefresh }: modalTrasnfProps){
    const { user } = useContext(AuthContext)
    const [mesaOrigem, setMesaOrigem] = useState('')
    const [mesaDestino, setMesaDestino] = useState('')
    const [loading, setLoading] = useState(false)
    
    async function handleTransferir(){
        if(mesaOrigem == '' && mesaDestino == ''){
            toast.warn('Digite a mesa de origem e destino!')
            return
        }
    
        if(!isNumber(mesaOrigem)){
            toast.warn('Mesa de origem inválida!')
            return
        }

        if(!isNumber(mesaDestino)){
            toast.warn('Mesa de destino inválida!')
            return
        }

        if (parseInt(mesaOrigem) <= 0 || parseInt(mesaOrigem) > 140){
            toast.warn('Mesa de origem inválida!')
            return
        }

        if (parseInt(mesaDestino) <= 0 || parseInt(mesaDestino) > 140){
            toast.warn('Mesa de destino inválida!')
            return
        } 
        
        try{
            const ocupada = mesas.includes(parseInt(mesaOrigem))
            
            if(!ocupada){
                toast.warn('Não existe itens na mesa de origem!')
                return                
            }

            setLoading(true) 

            let obj = {
                empresa: user?.id,
                mesaorigem: mesaOrigem,
                mesadestino: mesaDestino
            }

            const apiClient = setupApiClient()
            const response = await apiClient.put('/transferirmesa', obj)

            if(response.data?.COD == 200){
                setLoading(false)
                onRefresh()
                onClose()
                toast.success(`Mesa transferida!`)
            } else {
                setLoading(false)
                toast.error(response.data?.COD+' - '+response.data?.MSG)
                return  
            }
        } catch(e){
            console.log(e)
            toast.error('Erro ao transferir a mesa!')
            setLoading(false)
            return
        }
    }
    
    return(
        <div className='containerModal'>
            <div className='contentModalDetalhe'>
                <div className='btnCloseModal transfMesa'>
                    <button onClick={onClose}>
                        <FaWindowClose size={20} color='#FFF'/>
                    </button>
                    <h4>Transferir itens de uma mesa para outra</h4>
                </div>

                <div className='div-ref-mesas'>
                    <div className='div-input-transf'>
                        <label htmlFor="mesa-origem">MESA DE ORIGEM</label>
                        <input
                            id='mesa-origem'
                            type="text" 
                            placeholder='0'
                            required
                            value={mesaOrigem}
                            onChange={(e) => setMesaOrigem(e.target.value)}
                        />  
                    </div>
                    <div className='div-input-transf'>
                        <label htmlFor="mesa-destino">MESA DE DESTINO</label>
                        <input 
                            id='mesa-destino'
                            type="text"
                            required 
                            placeholder='0'
                            value={mesaDestino}
                            onChange={(e) => setMesaDestino(e.target.value)}
                        />  
                    </div>
                </div>

                <div className='btnFecharMesa'>
                    <button
                        className='btn-salvar-transnf'
                        onClick={handleTransferir}
                    >
                        {loading ? (
                            <MdRefresh className='svg-enviar' size={25} color='#fff'/>   
                        ) : (
                            <>
                                <BiTransfer size={25} color='#fff'/> 
                                TRANSFERIR
                            </>
                        )}
                    </button> 
                </div>
                <div className='txt-atencao'>
                    <h2>
                        Atenção, confira se as mesas digitadas estão corretas, pois não será possível reverter a transferência dos itens!      
                    </h2>
                </div>
            </div>
        </div>
    )
}