import React, { useRef, useState } from 'react';
import './index.css'
import { MdBorderColor } from 'react-icons/md'
import { BiFoodMenu, BiCategory } from 'react-icons/bi'
import { GiRoundTable, GiMoneyStack } from 'react-icons/gi'
import { FaPrint } from "react-icons/fa"
import { TiPrinter } from "react-icons/ti"
import { Swiper, SwiperSlide } from 'swiper/react';
import { EffectCards } from 'swiper/modules';


export default function Funcionalidades(){
    return(
        <section className='sec-containerfunc'>


            <div className='div-texto-banner2'>
                <div className='div-texto1'>
                    <span>Funcionalidades</span>
                    <ul>
                        <li>
                            <MdBorderColor size={30} color='#000'/>
                            <span>Controle de pedidos</span>                          
                        </li>
                        <li>
                            <BiCategory size={30} color='#000'/>  
                            <span>Separe os produtos por categorias e facililte a busca dos produtos</span>                              
                        </li>
                        <li>
                            <BiFoodMenu size={30} color='#000'/>   
                            <span>Cardápio online personalizado com QR Code</span>                             
                        </li>
                        <li>
                            <GiRoundTable size={30} color='#000'/>     
                            <span>Controle de mesas</span>                          
                        </li>
                        <li>
                            <GiMoneyStack size={30} color='#000'/>     
                            <span>Controle a entrada e saída do caixa de forma simples</span>                               
                        </li>
                        <li>
                            <TiPrinter size={30} color='#000'/>     
                            <span>Emissão de cupom fiscal S@T e nota fiscal paulista</span>                             
                        </li>
                        <li>
                            <FaPrint  size={30} color='#000'/>     
                            <span>Emissão de NFe "DANFE" para empresas</span>                               
                        </li>
                    </ul>
                </div>

                <div className='div-texto2'>
                    <Swiper
                        effect={'cards'}
                        grabCursor={true}
                        modules={[EffectCards]}
                        className="mySwiper"
                    >
                        <SwiperSlide>
                            <div className='slide'>
                                <MdBorderColor size={80} color='#fff'/>
                                <span>CONTROLE DE PEDIDOS</span>    
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className='slide'>
                                <BiCategory size={80} color='#fff'/>  
                                <span>CATEGORIAS</span>    
                            </div>                            
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className='slide'>
                                <BiFoodMenu size={80} color='#fff'/>   
                                <span>CARDÁPIO ONLINE</span>    
                            </div>                              
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className='slide'>
                                <GiRoundTable size={80} color='#fff'/>     
                                <span>CONTROLE DE MESAS</span>    
                            </div>                              
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className='slide'>
                                <GiMoneyStack size={80} color='#fff'/>     
                                <span>CONTROLE DE CAIXA</span>    
                            </div>                              
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className='slide'>
                                <TiPrinter size={80} color='#fff'/>     
                                <span>EMISSÃO DE CUPOM S@T</span>    
                            </div>                             
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className='slide'>
                                <FaPrint  size={80} color='#fff'/>     
                                <span>EMISSÃO DE NFe</span>    
                            </div>                             
                        </SwiperSlide>
                    </Swiper>
                </div>
            </div>
        </section>
    )
}