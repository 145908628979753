
import { useContext } from 'react'
import './index.css'
import { FaWindowClose, FaAmericanSignLanguageInterpreting } from 'react-icons/fa'
import { BsCheck } from 'react-icons/bs'
import { MdPrint } from "react-icons/md"
import { ItensMesaProps } from '../../pages/NovoPedido'; 
import { AuthContext } from '../../contexts/AuthContext'

export interface DetalhePedido{
    ID: string;
    MESA: number | string;
    NOME: string;
    ITENS: ItensMesaProps[]
}

interface ModalDetalheProps{
    data: DetalhePedido
    obspedido?: string
    onClose: () => void;
    onRequest: (id: string) => Promise<void>
    onRequestPreparacao: (id: string) => Promise<void>
}

export default function ModalDetalhe({ data, obspedido, onClose, onRequest, onRequestPreparacao }: ModalDetalheProps){
    const { user } = useContext(AuthContext)

    const dt = new Date().getDate()
    const mes = new Date().getMonth() + 1 
    const ano = new Date().getFullYear()
    const hora = new Date().getHours().toLocaleString('pt-BR')
    const min = new Date().getMinutes().toLocaleString('pt-BR')

    function handleImprimir(){
        window.print()
    }
    
    return(
        <div className='containerModal'>
            <div className='contentModalDetalhe'>
                <div className='btnCloseModal'>
                    <button onClick={onClose}>
                        <FaWindowClose size={20} color='#FFF'/>
                    </button>
                    <h2>DETALHES DA MESA - {data?.MESA}</h2>
                </div>

                <div className='headerDetalhe'>
                    <h3>NOME: {data?.NOME ? data?.NOME : 'SEM NOME'}</h3>
                </div>
                
                <div className='itensDetalhe'>
                    { data.ITENS.map( (item, index) => {
                        return(
                            <div key={index} className='notborder'>
                                <span>{item?.descricao}</span>
                                <div className='pedidomesa'>
                                    <h4>{item?.nomeprod}</h4> 
                                    <h4>{item?.quant.toFixed(2)}</h4>
                                </div>
                            </div>
                        )
                    })}
                </div>

                <div className='div-obspedido'>
                    <label>OBSERVAÇÕES</label>
                    <span>{obspedido}</span>
                </div>

                <div className='boxbtn'>
                    <button 
                        className='btnImprimir'
                        onClick={handleImprimir}
                    >
                        <MdPrint size={25} color='#FFF'/>
                        IMPRIMIR
                    </button>
                    
                    <button className='btnpreparacao' onClick={() => onRequestPreparacao(data?.ID)}>
                        <FaAmericanSignLanguageInterpreting size={25} color='#FFF'/>
                        EM PREPARAÇÃO
                    </button>

                    <button className='btnEntregue' onClick={ () => onRequest(data?.ID)}>
                        <BsCheck size={25} color='#FFF'/>
                        ENTREGUE
                    </button>
                </div>    
            </div>

            <div className='printItensPedido'>
                <div className='printInfo'>
                    <h4>{user?.name}</h4>
                    <h5>{dt < 9 ? `0`+dt : dt}/{mes < 9 ? `0`+mes : mes}/{ano}  {hora}:{parseInt(min) < 9 ? '0'+min : min}</h5>    
                </div>

                <div className='printHeader'>
                    <h4>MESA - {data?.MESA}</h4>
                    <span>{data?.NOME}</span>
                </div>

                {data?.ITENS.map( (item, index) => {
                    return(
                        <div key={index} className='printDetail'>
                            <span>QTD: {item?.quant.toFixed(2)} - {item?.nomeprod}</span>
                        </div>
                    )
                })}

                <div className='print-obs'>
                    <h6>OBSERVAÇÕES</h6>                       
                    <span>{obspedido}</span>
                </div>

                <div className='printFooter'>
                    <h5>Sistema MeuBar</h5>
                </div>
            </div>
        </div>
    )
}

