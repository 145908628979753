import HeaderHome from '../../components/Home/Header'
import BannerTopo from '../../components/Home/BannerTopo'
import Funcionalidades from '../../components/Home/Funcionalidaes'
import Planos from '../../components/Home/Planos'
import BannerRodape from '../../components/Home/BannerRodape'
import Contato from '../../components/Home/Contato'
import FooterHome from '../../components/Home/Rodape'

import MenuMobile from '../../components/Home/MenuMobile'

export default function Home(){


    function handletoggle(){
        const nav = document.querySelector('.menumobile-box')
        nav.classList.toggle('activemenu')   
    }

    return(
        <>
            <HeaderHome
                onOpenMenu={handletoggle}
            />
            <BannerTopo/>
            <Funcionalidades/>
            <Planos/>  
            <BannerRodape/>   
            <Contato/>
            <FooterHome/> 

            <MenuMobile
                openMenu={handletoggle}   
            />            
        </>
    )
}