import { useContext, FormEvent, useState } from 'react'
import './index.css'
import Header from '../../components/Header'
import Footer from '../../components/Footer'
import logoImg from '../../assets/logo.svg'
import { Input } from '../../components/Input'
import { Button } from '../../components/Button'
import { toast } from 'react-toastify'
import { useEffect } from 'react'
import { AuthContext } from '../../contexts/AuthContext'
import { setupApiClient } from '../../services/api'
import { useNavigate } from 'react-router-dom'
import { MdHome, MdEmail, MdRefresh, MdOutlinePayments } from "react-icons/md"
import { HiLockClosed, HiLockOpen  } from "react-icons/hi"
import { IoCheckmarkDoneSharp } from "react-icons/io5"
import { Link } from 'react-router-dom'
import { getStripeJS } from '../../services/stripe-js'
import { validaDataModuloTeste } from '../../utils/utils'

export default function MinhaConta(){
    const { user, updateUser, diasUso, validarPremium, updateDiasUso } = useContext(AuthContext)
    const navigate = useNavigate()
    
    const [nome, setNome] = useState(user?.name)
    const [senha, SetSenha] = useState('')
    const [newSenha, setNewSenha] = useState('')
    const [confirmNewSenha, setConfirmNewSenha] = useState('')
    const [loading, setLoading] = useState(false)
    const [versenha, setVerSenha] = useState(false)
    const [planoatual, setPlanoAtual] = useState(0)
    const [planos, setPlanos] = useState(['BÁSICO','CONTROLE','AVANÇADO'])

    useEffect( () => {
        async function getDadosUser(){
            try{
                const apiClient = setupApiClient()

                const respUser = await apiClient.get('/me',{
                    params:{
                        email: localStorage.getItem('@emailAcesso')
                    }
                })

                const { ID, NOME, EMAIL, CRIADO, BLOQUEADO, PAGO, MODULO } = respUser.data;

                let data = {
                    id: ID,
                    name: NOME,
                    email: EMAIL,
                    bloqueado: BLOQUEADO,
                    pago: PAGO,
                    modulo: MODULO
                }
                
                sessionStorage.setItem('@id_meu_bar',ID)
                setPlanoAtual(MODULO)
                updateUser(data)
                setNome(NOME)
                
                if(String(MODULO) == process.env.REACT_APP_MODULO && validaDataModuloTeste(CRIADO) <= 0){
                    validarPremium(false)
                    updateDiasUso(validaDataModuloTeste(CRIADO))
                } else {
                    validarPremium(true)
                    updateDiasUso(validaDataModuloTeste(CRIADO))
                }

            } catch(e) {
                console.log(e)
            }
        }

        getDadosUser()
    },[])


    async function handleSalvar(e: FormEvent){
        e.preventDefault()
        
        if(nome === ''){
            toast.warn('Campo nome não pode ser vazio!')
            return
        }

        if(senha == ''){
            toast.warn('Digite a senha atual!')
            return            
        }

        if(newSenha != ''){
            if(newSenha === '' || confirmNewSenha === ''){
                toast.warn('Digite e confirme a nova senha!')
                return
            }
    
            if(newSenha !== confirmNewSenha){
                toast.warn('Nova senha não confere!')
                return
            }
        }

        try{
            const apiClient = setupApiClient()

            const resp = await apiClient.put('/user',{
                email: localStorage.getItem('@emailAcesso'),
                nome: nome,
                senha: senha,
                novasenha: newSenha               
            })

            if (resp.data?.COD == 200){
                const respUser = await apiClient.get('/me',{
                    params:{
                        email: localStorage.getItem('@emailAcesso')
                    }
                })
                const { ID, NOME, EMAIL, BLOQUEAOD } = respUser.data;
                        
                let data = {
                    id: ID,
                    name: NOME,
                    email: EMAIL,
                    bloqueado: BLOQUEAOD     
                }
    
                updateUser(data)
    
                toast.success('Dados atualizados com sucesso!')
                navigate('/dashboard')
            } else {
                toast.error(resp.data?.COD+' - '+resp.data?.MSG)
                return
            }

        } catch(error) {
            console.log(error)
            toast.error('Erro atualizando a conta!, verifique os dados digitados!')
            return
        }
    }

    async function handleAssinar(modulo: number){
        let text = document.getElementById(`btn-mod${modulo}`).innerText
        document.getElementById(`btn-mod${modulo}`).innerText = 'Aguarde....'

        try{
            let obj= {
                email: user?.email,
                modulo_id: String(modulo)
            }

            const apiClient = setupApiClient()

            const response = await apiClient.post('/assinatura', obj)           
            const { sessionId } = response.data
            const stripe = await getStripeJS()
            await stripe.redirectToCheckout({ sessionId: sessionId })
            .then( () => {
                document.getElementById(`btn-mod${modulo}`).innerText = text
            })

        } catch(e){
            console.log(e)
            document.getElementById(`btn-mod${modulo}`).innerText = text
            return
        }
    }

    async function handlePortalPag(){
        document.getElementById(`btnportal`).innerText = 'Aguarde....'

        try{
            let obj= {
                email: user?.email
            }

            const apiClient = setupApiClient()

            const response = await apiClient.post('/portal-pag', obj)           
                if(response.data?.COD == 400){
                    toast.error(response.data?.MSG)
                    document.getElementById(`btnportal`).innerText = 'ALTERAR FORMA DE PAGAMENTO'
                    return 
                } else {
                    if(response.data?.COD == 200){
                        const { sessionId } = response.data
                        window.location.href = sessionId
                } else {
                    toast.error('Erro abrindo o portal de assinaturas!')
                    document.getElementById(`btnportal`).innerText = 'ALTERAR FORMA DE PAGAMENTO'
                    return                
                }
            }
        } catch(e){
            console.log(e)
            document.getElementById(`btnportal`).innerText = 'Erro....'
            return
        }        
    }

    return(
        <main>
            <Header
                menu={0}
            />
            <section className='containerConta'>
                <div className='div-container-conta'>

                    <div className='headerConta'>
                        <img  src={logoImg} width={150} />
                        {String(planoatual) == process.env.REACT_APP_MODULO && diasUso <= 0 ? (
                            <>
                                <span>Minha conta {diasUso <= 0 && `- Você tem ${diasUso} dias de uso.`}</span>
                                <span>Seu período de teste terminou, escolha um plano para continuar!</span>
                            </>
                        ) : (
                            <span>Minha conta - CLIENTE PREMIUM</span>
                        )}
                    </div>

                    <div className='boxcustomer'>
                        <form className='formConta' onSubmit={handleSalvar}>
                            <div className='div-linha'>
                                <MdHome size={25} color='#fff' />
                                <Input
                                    placeholder='Nome fantasia...'
                                    type='text'
                                    value={nome}
                                    onChange={ (e) =>  setNome(e.target.value)}
                                    required
                                />
                            </div>

                            <div className='div-linha'>
                                <MdEmail size={25} color='#fff' />
                                <Input
                                    style={ {cursor:'no-drop', opacity:'0.5' } }
                                    placeholder='Seu email...'
                                    type='text'
                                    value={user?.email}
                                    disabled={true}
                                />
                            </div>

                            <div className='div-linha'>
                                { versenha ? (
                                    <HiLockOpen style={{cursor:'pointer'}} onClick={() => setVerSenha(!versenha)} size={25} color='#fff' />
                                ) : (
                                    <HiLockClosed style={{cursor:'pointer'}} onClick={() => setVerSenha(!versenha)} size={25} color='#fff' />
                                )}
                                <Input
                                    placeholder='Senha atual...'
                                    type={versenha ? 'text' : 'password'}
                                    value={senha}
                                    onChange={ (e) => SetSenha(e.target.value)}
                                    required
                                />
                            </div>

                            <div className='div-linha'>
                                { versenha ? (
                                    <HiLockOpen style={{cursor:'pointer'}} onClick={() => setVerSenha(!versenha)} size={25} color='#fff' />
                                ) : (
                                    <HiLockClosed style={{cursor:'pointer'}} onClick={() => setVerSenha(!versenha)} size={25} color='#fff' />
                                )}
                                <Input
                                    placeholder='Nova senha...'
                                    type={versenha ? 'text' : 'password'}
                                    value={newSenha}
                                    onChange={ (e) => setNewSenha(e.target.value)}
                                />
                            </div>

                            <div className='div-linha'>
                                { versenha ? (
                                    <HiLockOpen style={{cursor:'pointer'}} onClick={() => setVerSenha(!versenha)} size={25} color='#fff' />
                                ) : (
                                    <HiLockClosed style={{cursor:'pointer'}} onClick={() => setVerSenha(!versenha)} size={25} color='#fff' />
                                )}
                                <Input
                                    placeholder='Confirmar nova senha...'
                                    type={versenha ? 'text' : 'password'}
                                    value={confirmNewSenha}
                                    onChange={ (e) => setConfirmNewSenha(e.target.value)}
                                />               
                            </div>

                            <Button
                                type='submit'
                            >
                                { loading ? (
                                    <MdRefresh className='svg-enviar' size={25} color='#fff'/>
                                ):(
                                    <>
                                    <IoCheckmarkDoneSharp style={{marginRight:5}} size={20} color='#fff'/>
                                    SALVAR
                                    </>
                                )}
                            </Button>    
                        </form>

                        <div className='container-planos'>
                            <div className='div-txt-planos'>
                                <h5>PLANO ATUAL</h5>
                                { planoatual == 0 ? (
                                    <span>Plano experimental</span>
                                ) : (
                                    <span>{planos[planoatual-1]}</span>
                                )}
                            </div>
                            { planoatual == 0 ? (
                                <div className='div-btn-planos'>
                                    <Link to='https://www.meubar.app.br/' target='_blank'>
                                        CONHECER OS MÓDULOS
                                    </Link> 
                                    <button id='btn-mod1' onClick={() => handleAssinar(1)}>
                                        QUERO O BÁSICO - R$ 99,90
                                    </button>
                                    <button id='btn-mod2' onClick={() => handleAssinar(2)}>
                                        QUERO O CONTROLE - R$ 149,90
                                    </button>
                                    <button id='btn-mod3' onClick={() => handleAssinar(3)}>
                                        QUERO O AVANÇADO - R$ 199,90
                                    </button>
                                </div>
                            ) : (
                                <div className='div-planopremium'>
                                    <span>Seu plano atual é o {planos[planoatual-1]}, caso queira fazer um upgrade, downgrade ou cancelar sua assinatura entre em contato conosco pelo email, telefone ou WhatsApp.</span>
                                    <button
                                        id='btnportal'
                                        onClick={handlePortalPag}
                                    >
                                        <MdOutlinePayments size={20} color='#fff'/>
                                        ALTERAR FORMA DE PAGAMENTO
                                    </button>
                                </div>
                            )}
                        </div>
                    </div>

                </div>





            </section>

            <Footer/>
            
            
            
            
            
            {/* <div className='containerConta'>

                <div className='headerConta'>
                    <img  src={logoImg} width={150} />
                    {String(planoatual) == process.env.REACT_APP_MODULO && diasUso <= 0 ? (
                        <>
                            <span>Minha conta {diasUso <= 0 && `- Você tem ${diasUso} dias de uso.`}</span>
                            <span>Seu período de teste terminou, escolha um plano para continuar!</span>
                        </>
                    ) : (
                        <span>Minha conta - Premium</span>
                    )}
                </div>

                <div className='boxcustomer'>
                    <form className='formConta' onSubmit={handleSalvar}>
                        <div className='div-linha'>
                            <MdHome size={25} color='#fff' />
                            <Input
                                placeholder='Nome fantasia...'
                                type='text'
                                value={nome}
                                onChange={ (e) =>  setNome(e.target.value)}
                                required
                            />
                        </div>

                        <div className='div-linha'>
                            <MdEmail size={25} color='#fff' />
                            <Input
                                style={ {cursor:'no-drop', opacity:'0.5' } }
                                placeholder='Seu email...'
                                type='text'
                                value={user?.email}
                                disabled={true}
                            />
                        </div>

                        <div className='div-linha'>
                            { versenha ? (
                                <HiLockOpen style={{cursor:'pointer'}} onClick={() => setVerSenha(!versenha)} size={25} color='#fff' />
                            ) : (
                                <HiLockClosed style={{cursor:'pointer'}} onClick={() => setVerSenha(!versenha)} size={25} color='#fff' />
                            )}
                            <Input
                                placeholder='Senha atual...'
                                type={versenha ? 'text' : 'password'}
                                value={senha}
                                onChange={ (e) => SetSenha(e.target.value)}
                                required
                            />
                        </div>

                        <div className='div-linha'>
                            { versenha ? (
                                <HiLockOpen style={{cursor:'pointer'}} onClick={() => setVerSenha(!versenha)} size={25} color='#fff' />
                            ) : (
                                <HiLockClosed style={{cursor:'pointer'}} onClick={() => setVerSenha(!versenha)} size={25} color='#fff' />
                            )}
                            <Input
                                placeholder='Nova senha...'
                                type={versenha ? 'text' : 'password'}
                                value={newSenha}
                                onChange={ (e) => setNewSenha(e.target.value)}
                            />
                        </div>

                        <div className='div-linha'>
                            { versenha ? (
                                <HiLockOpen style={{cursor:'pointer'}} onClick={() => setVerSenha(!versenha)} size={25} color='#fff' />
                            ) : (
                                <HiLockClosed style={{cursor:'pointer'}} onClick={() => setVerSenha(!versenha)} size={25} color='#fff' />
                            )}
                            <Input
                                placeholder='Confirmar nova senha...'
                                type={versenha ? 'text' : 'password'}
                                value={confirmNewSenha}
                                onChange={ (e) => setConfirmNewSenha(e.target.value)}
                            />               
                        </div>

                        <Button
                            type='submit'
                        >
                            { loading ? (
                                <MdRefresh className='svg-enviar' size={25} color='#fff'/>
                            ):(
                                <>
                                <IoCheckmarkDoneSharp style={{marginRight:5}} size={20} color='#fff'/>
                                Salvar
                                </>
                            )}
                        </Button>    
                    </form>

                    <div className='container-planos'>
                        <div className='div-txt-planos'>
                            <h5>Plano atual</h5>
                            { planoatual == 0 ? (
                                <span>Plano experimental</span>
                            ) : (
                                <span>Plano - Módulo {planoatual}</span>
                            )}
                        </div>
                        { planoatual == 0 ? (
                            <div className='div-btn-planos'>
                                <Link to='https://www.meubar.app.br/' target='_blank'>
                                    Conhecer os módulos
                                </Link> 
                                <button id='btn-mod1' onClick={() => handleAssinar(1)}>
                                    Quero o módulo 1 - R$ 99,90
                                </button>
                                <button id='btn-mod2' onClick={() => handleAssinar(2)}>
                                    Quero o módulo 2 - R$ 149,90
                                </button>
                                <button id='btn-mod3' onClick={() => handleAssinar(3)}>
                                    Quero o módulo 3 - R$ 199,90
                                </button>
                            </div>
                        ) : (
                            <div className='div-planopremium'>
                                <span>Seu módulo atual é {planoatual}, caso queira fazer um upgrade, downgrade ou cancelar sua assinatura entre em contato conosco pelo email, telefone ou WhatsApp.</span>
                                <button
                                    id='btnportal'
                                    onClick={handlePortalPag}
                                >
                                    <MdOutlinePayments size={20} color='#fff'/>
                                    ALTERAR FORMA DE PAGAMENTO
                                </button>
                            </div>
                        )}
                    </div>
                </div>
            </div> */}


        </main>
    )
}