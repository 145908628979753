import { Routes, Route } from "react-router-dom";
import Private from "./private";
import { useContext } from "react";

import { AuthContext } from "../contexts/AuthContext";
import Home from "../pages/Home";
import Login from "../pages/Login";
import Dashboard from "../pages/Dashboard";
import Pedidos from "../pages/Pedidos";
import NovoPedido from "../pages/NovoPedido";
import Categoria from "../pages/Categoria";
import Cardapio from "../pages/Cardapio";
import NovoProduto from "../pages/NovoProduto";
import Mesas from "../pages/Mesas";
import Caixa from "../pages/Caixa";
import NotFound from "../pages/NotFound";
import MinhaConta from "../pages/MinhaConta";
import Cadastro from "../pages/Cadastro";
import QuemSomos from "../pages/QuemSomos";

import DurvaRockBar from "../pages/CardapioDurva";

export default function RoutesApp(){
    const { premium } = useContext(AuthContext)
    
    return(
        <Routes>
            <Route path="/" element={ <Home/> }/>    
            <Route path="/login" element={ <Login/> } />
            <Route path="/quemsomos" element={ <QuemSomos/> }/>
            <Route path="/cadastro/:id?" element={ <Cadastro/> } />

            <Route path="/durvarockbar/:id?" element={ <DurvaRockBar/> } />

            <Route path="/account" element={ <Private> <MinhaConta/> </Private> } />
            <Route path="/dashboard" element={ <Private> {premium ? <Dashboard/> : <MinhaConta/> }</Private> } />
            <Route path="/pedidos" element={  <Private> { premium ? <Pedidos/> : <MinhaConta/> } </Private> } />
            <Route path="/pedidos/new/:id?" element={  <Private> {premium ? <NovoPedido/> : <MinhaConta/> } </Private>  } />            
            <Route path="/categoria" element={ <Private> {premium ? <Categoria/> : <MinhaConta/> } </Private> } />  
            <Route path="/produtos" element={ <Private> {premium ? <Cardapio/> : <MinhaConta/> }</Private> } />   
            <Route path="/produtos/new/:id?" element={ <Private> {premium ? <NovoProduto/> : <MinhaConta/> } </Private> } />               
            <Route path="/mesas" element={ <Private> {premium ? <Mesas/> : <MinhaConta/> } </Private> } />    
            <Route path="/caixa" element={ <Private> {premium ? <Caixa/> : <MinhaConta/>} </Private> } />                                              
        
            <Route path="*" element={ <NotFound/> } />  
        </Routes>
    )
}