import './index.css'
import { useEffect, useContext } from 'react'
import { DetalhePedido } from '../ModalDetalhe'
import { AuthContext } from '../../contexts/AuthContext'

interface ModalPrintProps{
    data: DetalhePedido
    imprimir: boolean
    obspedido?: string
    onClose: () => void
}

export default function ModalPrint({ data, imprimir, obspedido, onClose }: ModalPrintProps){
    const { user } = useContext(AuthContext)
    const dt = new Date().getDate()
    const mes = new Date().getMonth() + 1 
    const ano = new Date().getFullYear()
    const hora = new Date().getHours().toLocaleString('pt-BR')
    const min = new Date().getMinutes().toLocaleString('pt-BR')

    useEffect( () => {
        if(imprimir){
            window.print()
        }
        onClose()
    },[])

    return(
        <div className='containerModalPrint'>

            <div className='printDetalhePedido'>
                <div className='printDetalheInfo'>
                    <h4>{user?.name}</h4>
                    <h5>{dt < 9 ? `0`+dt : dt}/{mes < 9 ? `0`+mes : mes}/{ano}  {hora}:{parseInt(min) < 9 ? '0'+min : min}</h5>    
                </div>

                <div className='printDetalheHeader'>
                    <h4>MESA - {data?.MESA}</h4>
                    <span>{data?.NOME}</span>
                </div>

                {data?.ITENS.map( (item, index) => {
                    return(
                        <div key={index} className='printDetalheDetail'>
                            <span>QTD: {item?.quant.toFixed(2)} - {item?.nomeprod}</span>
                        </div>
                    )
                })}

                <div className='print-Detalhe-obs'>
                    <h6>OBSERVAÇÕES</h6>                       
                    <span>{obspedido}</span>
                </div>

                <div className='printDetalheFooter'>
                    <h5>www.meubar.app.br - Sistema MeuBar</h5>
                </div>
            </div>

        </div>
    )
}