import { useState, useEffect } from 'react'
import './index.css'
import Header from "../../components/Header"
import Footer from '../../components/Footer'
import { GiRoundTable } from 'react-icons/gi'
import { HiOutlineRefresh } from 'react-icons/hi'
import { BiTransfer } from "react-icons/bi"
import Loading from '../../components/Loading'
import { setupApiClient } from '../../services/api'
import { toast } from 'react-toastify'
import ModalFechamento from '../../components/ModalFechamento'
import { ValoresCaixaProps } from '../../components/ModalFechamento'
import { ItensPrintProps } from '../../components/ModalFechamento'
import ModalTransferMesa from '../../components/ModalTransfMesa'

interface MesaProps{
    NUM: number;
    NOME: string;
}

export interface TotalNomeProps{
    NOME: string
    UUID: string
    TOTAL: number
}

export interface FechamentoMesaProps{
    MESA: number
    NOME: string
    TOTALMESA: number
    ITENS: ItensPrintProps[]        
}

export default function Mesas(){
    const [loading, setLoading] = useState(false)
    const [numMesas, setNumMesas] = useState<MesaProps[]>([])
    const [mesasAbertas, setMesasAbertas] = useState<MesaProps[]>([])
    const [totalNomes, setTotalNomes] = useState<TotalNomeProps[]>([])
    const [ocupada, setOcupada] = useState([])
    const [fechamentoMesa, setFechamentoMesa] = useState<FechamentoMesaProps>()
    const [printItens, setPrintItens] = useState<ItensPrintProps[]>([])
    const [refreshMesas, setRefreshMesas] = useState(false)
    const [openModal, setOpenModal] = useState(false)
    const [modaltransfMesa, setModalTransfMesa] = useState(false)

    useEffect( () => {
        async function getMesasAbertas(){
            setLoading(true)

            try{
                const apiClient = setupApiClient()

                const response = await apiClient.get('/mesas',{
                    params:{
                        empresa: sessionStorage.getItem('@id_meu_bar')
                    }
                })

                let mesas = []
                if(response.data.length > 0){
                    response.data.map( (item: any) => {
                        mesas.push({
                            NUM: item?.MESA,
                            NOME: item?.NOME_CLIENTE
                        })
                    })
                }

                setMesasAbertas(mesas)
                setLoading(false)
            } catch(e) {
                console.log(e)
                toast.error('Erro buscando mesas abertas!')
                setMesasAbertas([])
                setLoading(false)
            }
        }

        getMesasAbertas()
    },[refreshMesas])


    useEffect( () => {
        function countMesa(){
            let arr = []
            for(let i:number = 1; i <= 140; i++){
                arr.push({
                    NUM: i,
                    NOME: ''
                })
            }

            let mesaOcupada = []
            mesasAbertas.map( (item) => { 
                const idx = arr.findIndex(arr => arr.NUM === item.NUM)
                
                if (arr[idx].NOME === ''){
                    arr[idx].NOME = item.NOME
                } 

                mesaOcupada.push(item?.NUM)
            })

            setNumMesas(arr)
            setOcupada(mesaOcupada)
        }

        countMesa()

    },[mesasAbertas])

    async function handleModalMesa(mesa: number){

        const fechar = ocupada.find(ocupada => ocupada === mesa)

        if(fechar === undefined){
            return
        }
        
        try{
            setLoading(true)

            const apiClient = setupApiClient()

            const response = await apiClient.get('/fecharmesa',{
                params:{
                    empresa: sessionStorage.getItem('@id_meu_bar'),
                    mesa: mesa
                }
            })

            let print: any[] = []
            let arrNomes: any[] = []
            response.data.map( (mesa: any) => {
                print.push({
                    ID: mesa?.PRODUTO_ID,
                    NOME: mesa?.NOMEPRODUTO,
                    QUANT: mesa?.QUANTIDADE,
                    UNITARIO: mesa?.VALOR_UNITARIO,
                    TOTAL: mesa?.TOTAL                       
                })

                const idx = arrNomes.findIndex(n => n.UUID == mesa?.UUID)
                if(idx > -1){
                    arrNomes[idx].TOTAL += mesa?.TOTAL
                } else {
                    arrNomes.push({
                        NOME: mesa?.NOME_CLIENTE,
                        UUID: mesa?.UUID,
                        TOTAL: mesa?.TOTAL
                    })
                }
            })

            setTotalNomes(arrNomes)

            let arrPrint = []
            print.map( (item: any) => {
                const idx = arrPrint.findIndex(arr => arr.ID === item.ID)

                if (idx > -1){
                    if(arrPrint[idx].UNITARIO == item?.UNITARIO){
                        arrPrint[idx].QUANT += item?.QUANT
                        arrPrint[idx].TOTAL = arrPrint[idx].UNITARIO * arrPrint[idx].QUANT 
                    } else {
                        arrPrint.push({
                            ID: item?.ID,
                            NOME: item?.NOME,
                            QUANT: item?.QUANT,
                            UNITARIO: item?.UNITARIO,
                            TOTAL: item?.TOTAL   
                        })
                    }
                } else {
                    arrPrint.push({
                        ID: item?.ID,
                        NOME: item?.NOME,
                        QUANT: item?.QUANT,
                        UNITARIO: item?.UNITARIO,
                        TOTAL: item?.TOTAL   
                    })
                }
            })

            let totalmesa: number = 0
            arrPrint.map( (e: any) => {
                totalmesa += e.TOTAL
            })

            setFechamentoMesa({
                MESA: response.data[0].MESA,
                NOME: response.data[0].NOME_CLIENTE,
                TOTALMESA: totalmesa,
                ITENS: arrPrint 
            })

            setLoading(false)
            setOpenModal(true)
        } catch {
            setLoading(false)
            toast.error('Erro fechamento da mesa!')
        }
    }

    async function handleMudarMesa(){
        setModalTransfMesa(true)
    }

    async function handleFecharMesa(dados: ValoresCaixaProps) {
        try{
            setLoading(true)

            const apiClient = setupApiClient()

            const response = await apiClient.post('/caixa',{
                empresa: sessionStorage.getItem('@id_meu_bar'), 
                mesa: dados?.mesa, 
                formapagto: dados?.forma, 
                valor: dados?.total,
                descricao: 'PAGAMENTO MESA: '+dados?.mesa
            })

            if(response.data?.COD == 201){
                setRefreshMesas(!refreshMesas)
                setLoading(false)
                setOpenModal(false)
                toast.success(`Mesa ${dados?.mesa} fechada!`)
            } else {
                setLoading(false)
                toast.error(response.data?.COD+' - '+response.data?.MSG)
                return  
            }
        } catch(e) {
            console.log(e)
            setLoading(false)
            toast.error('Erro fechando a mesa!')
        }
    }

    if(loading){
        return(
            <main>
                <Header
                    menu={4}
                />
                <Loading/>
                <Footer/>
            </main>
        )
    }

    return(
        <main>
            <Header
                menu={4}
            />
            <section className='containerMesas'>
                <div className='div-content-mesas'>
                    <div className='refresMesa'>
                        <h1>CONTROLE DE MESAS</h1>

                        <button onClick={ () => setRefreshMesas(!refreshMesas)} title='ATUALIZAR'>
                            <HiOutlineRefresh size={30} color='#F21A22'/> 
                            ATUALIZAR                   
                        </button>   
                        <button className='btn-transfer' onClick={handleMudarMesa}>
                            <BiTransfer size={25} color='#F21A22'/>
                            TRANSFERIR MESA
                        </button>
                    </div>

                    <div className='gridMesas'>
                        { numMesas.length > 0 && numMesas.map( (mesa) => {
                        return(
                            <div key={mesa?.NUM} className='gridItem'>
                                <button onClick={ () => handleModalMesa(mesa?.NUM)}>
                                    <GiRoundTable 
                                        size={50} 
                                        color={mesa?.NUM === ocupada.find(ocupada => ocupada === mesa?.NUM) ? '#ff3f4b' : '#2ECC71'}
                                    />
                                    <span>{mesa?.NUM}</span>
                                    <p>{mesa?.NUM === ocupada.find(ocupada => ocupada === mesa?.NUM) ? mesa?.NOME : ''}</p>
                                </button>
                            </div> 
                            )
                        })}
                    </div>

                </div>

            </section>

            { openModal && !loading && (
                <ModalFechamento
                    data={fechamentoMesa}
                    totalPorNome={totalNomes}
                    onClose={ () => setOpenModal(false)}
                    onRequest={ (dados) => handleFecharMesa(dados)}
                />
            )}

            {modaltransfMesa && (
                <ModalTransferMesa
                    mesas={ocupada}
                    onClose={() => setModalTransfMesa(!modaltransfMesa)}
                    onRefresh={() => setRefreshMesas(!refreshMesas)}
                />
            )}

            <Footer/>
        </main>
    )
}