import { BrowserRouter } from 'react-router-dom'
import RoutesApp from './routes';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { AuthProvider } from './contexts/AuthContext';

import './App.css';

import 'swiper/css';
import 'swiper/css/effect-cards';

function App() {
  return (
    <BrowserRouter>
      <AuthProvider>
        <ToastContainer autoClose={2000}/>
        <RoutesApp/> 
      </AuthProvider> 
    </BrowserRouter>
  );
}

export default App;
