
export const emailTest = /^[a-z0-9.]+@[a-z0-9]+\.[a-z]+(\.[a-z]+)?$/i

export function textTransform(value: string): string{
    if(value.length > 0){
        let texto: string = ''
    
        texto = value[0].toUpperCase() + value.toLowerCase().substring(1)
        return texto
    } else {
        return ''
    }
}

export function converterDataParaTexto(data: any): string{
    let newData = data.split('-')
    
    if(newData.length === 0){
        return null
    } else {
        return newData[2].substring(0,2)+'/'+newData[1]+'/'+newData[0]
    }
}

export function formataDataHoraToString(data: string): string{
    let newData = data.split('-')

    if(newData.length === 0){
        return null
    } else {
        let stringData = newData[2].substring(0,2)+'/'+newData[1]+'/'+newData[0]+' '+newData[2].substring(3,8)
        return stringData
    }
}

export function validaDataModuloTeste(data: string){
    const dataNow = new Date().getTime()
    const userCriado = new Date(data).getTime()
    const msegundos = dataNow - userCriado 
    const dias = msegundos / (1000 * 3600 * 24)

    if(dias > 7){
        return 0 
    } else {
        return dias
    }
}

export function isNumber(value: string){
    const num = /^[0-9]+$/
    return num.test(value)
}

export function adicionarMinutos(data:Date, minutos: number) {
    return new Date(data.getTime() + minutos * 60000);
}