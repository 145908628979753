import './index.css'
import { Link } from 'react-router-dom'
import { AiFillHome, AiFillPhone } from 'react-icons/ai'
import { BiTime } from 'react-icons/bi'
import { FaUserCheck, FaFlag, FaUserPlus } from 'react-icons/fa'
import { ImPriceTags } from 'react-icons/im'
import { FiMenu } from 'react-icons/fi'
import logoImg from '../../../assets/logo.svg'
import AnchorLink from 'react-anchor-link-smooth-scroll'

interface MenuProps{
    onOpenMenu: () => void;
}

export default function HeaderHome({ onOpenMenu }: MenuProps){
    return(
        <header className='headerhome-container'>
            <div className='headerhome-topo'>
                <ul>
                    <li>
                        <AiFillPhone color="#FFF" size={20}/>
                       <span>(19) 3546-5686</span>
                    </li>
                    <li>
                        <BiTime color='#FFF' size={20} />
                        <span>Segunda a sexta-feira das 08h00 às 18h00 (Horário de Brasília)</span>
                    </li>
                    <li className='acessar'>
                        <FaUserCheck color="#FFF" size={18}/>
                        <Link to='/login' target='_blank'>ACESSAR</Link>                       
                    </li>
                    <li>
                        <Link to='/cadastrouser' target='_blank'>TESTAR GRÁTIS</Link>                    
                    </li>
                </ul>
            </div> 

            <div className='headerhome-full'>
                <div className='headerhome-menu-mobile'>
                    <FiMenu size={45} color='#F21A22' onClick={onOpenMenu}/>
                </div> 

                <nav>
                    <ul>
                        <li>
                            <AiFillHome color="#F21A22" size={20}/> 
                            <Link to='/'>
                                HOME
                            </Link> 
                        </li>
                        <li>
                            <FaUserCheck color="#F21A22" size={20}/>
                            <Link to='/login' target='_blank'>
                                LOGIN
                            </Link> 
                        </li>
                        <li>
                            <FaUserPlus color="#F21A22" size={20}/> 
                            <Link to='/cadastro/0' target='_blank'>
                                TESTE GRÁTIS
                            </Link> 
                        </li>
                        <li>
                            <ImPriceTags color="#F21A22" size={20}/>
                            <AnchorLink href='#planos'>
                                PLANOS
                            </AnchorLink> 
                        </li>
                        <li> 
                            <FaFlag color="#F21A22" size={20}/>
                            <Link to='/quemsomos'>QUEM SOMOS</Link>
                        </li>
                        <li>
                            <AiFillPhone color="#F21A22" size={20}/>
                            <AnchorLink href='#contato'>
                                CONTATO
                            </AnchorLink> 
                        </li>
                    </ul>                
                </nav>
                <div className='logoMenu'>
                    <Link to='/'>
                        <img src={logoImg} alt='Logo' width='200' />
                    </Link>
                </div>
            </div>
        </header>
    )
}