import './index.css'
import banner from '../../../assets/img1.jpg'
import { Link } from 'react-router-dom'

export default function BannerTopo(){
    return(
        <section className='containerTopo'>
            <div className='div-container-sec1'>
                <div className='boximg'>
                    <img src={banner} alt='Banner1'/>
                </div>

                <div className='div-texto-banner1'>
                    <span>
                        Conheça o sistema MeuBar, um sistema para gerenciamento de mesas, pedidos, emissão de cupom fiscal S@T e fluxo de caixa para o seu negócio, podendo ser utilizado para bares, restaurantes, pizzarias e diversos estabelecimentos comerciais 100% online.                    
                    </span>

                    <div className='boxBtn'>
                        <Link to='/cadastro/0' target='_blank'>
                            TESTE AGORA GRATUITAMENTE
                        </Link>
                    </div>                   
                </div>
            </div>
        </section>
    )
    
}