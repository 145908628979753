import { FormEvent, useState, useContext, useEffect } from "react"
import { useParams, useNavigate } from "react-router-dom"
import Header from "../../components/Header"
import Footer from "../../components/Footer"
import './index.css'
import { Input } from "../../components/Input"
import { Button } from "../../components/Button"
import { IoMdAdd } from 'react-icons/io'
import { isNumber } from "../../utils/utils"
import { FaTrash } from 'react-icons/fa'
import { IoMdArrowBack } from 'react-icons/io'
import { MdSend, MdCancel } from 'react-icons/md'
import { toast } from 'react-toastify'
import { setupApiClient } from "../../services/api"
import { AuthContext } from "../../contexts/AuthContext"
import Loading from "../../components/Loading"
import Modal, { ItemProps } from "../../components/Modal"
import { CategoriaProps } from "../Categoria"

interface MesaProps{
    mesa: number | string
    nome: string
    empresa: string
    uuid?: string
    totalpedido: number
    pedido_id?: number
}

export interface ItensMesaProps{
    codigoprod: number
    nomeprod: string
    quant: number
    valor: number
    total: number
    descricao?: string
}

interface ProductProps{
    ID: string
    NOMEPRODUTO: string
    PRECO:  string
    URL?: string
    DESCRICAO?: string
}

export default function NovoPedido(){
    const navigate = useNavigate()
    const { id } = useParams()
    const { user } = useContext(AuthContext)

    const [categorias, setCategorias] = useState<CategoriaProps[] | []>([])
    const [categoriaSelecionada, setCategoriaSelecionada] = useState(0)
    const [produtos, setProdutos] = useState<ProductProps[]>([])
    const [dadosMesa, setDadosMesa] = useState<MesaProps>()
    const [itensMesa, setItensMesa] = useState<ItensMesaProps[]>([])
    const [produtoSelecionado, setProdutoSelecionado] = useState(0)
    const [loading, setLoading] = useState(false)
    const [numMesa, setNumMesa] = useState('')
    const [pedAtualizado, setPedAtualizado] = useState(false)
    const [nome, setNome] = useState('')
    const [obspedido, setObsPedido] = useState('')
    const [btnDisable, setBtnDisable] = useState(false)
    const [quant, setQuant] = useState('1')
    const [valorvenda, setValorVenda] = useState('0')
    const [desabilida, setdesabilita] = useState(true)
    const [totalPedido, setTotalPedido] = useState(0)
    const [openModal, setOpenModal] = useState(false)
    const [cancelPedido, setCancelPedido] = useState(false)
    const [itemDelete, setItemDelete] = useState<ItemProps>()

    useEffect( () => {
        async function getCategorias(){
            setLoading(true)
            try{
                const apiClient = setupApiClient()
                
                const response = await apiClient.get('/categorias',{
                    params:{
                        empresa: sessionStorage.getItem('@id_meu_bar')
                    }
                })
                
                response.data.length > 0 ? setCategorias(response.data) : setCategorias([])
                setCategoriaSelecionada(0)
                setLoading(false)
    
            } catch(error) {
                console.log(error.response)
                toast.error('Erro buscando categorias!')
                setCategorias([])
            }
        }
        getCategorias()
    },[])

    useEffect( () => {
        async function getProdutos(){
            setLoading(true)
            try{
                const apiClient = setupApiClient()
    
                const response = await apiClient.get('/produtosbycategoria',{
                    params:{
                        id_categoria: categorias[categoriaSelecionada]?.ID,
                        empresa: sessionStorage.getItem('@id_meu_bar')
                    }
                })

                if(response.data.length > 0){
                    setProdutos(response.data)
                    setValorVenda(response.data[0].PRECO) 
                    parseFloat(response.data[0].PRECO) <= 0 ? setdesabilita(false) : setdesabilita(true)
                } else {
                    setProdutos([])
                    setValorVenda('0')
                    setdesabilita(false)    
                }
                
            } catch(err) {
                console.log(err.response)
                toast.error('Erro buscando produtos!')
                setProdutos([])
            }
            setLoading(false)
        }
        getProdutos()

    },[categorias, categoriaSelecionada])

    useEffect( () => {
        async function getDetailPedido(){
            if(id){
                try{
                    setLoading(true)
                    const apiClient = setupApiClient()
        
                    const response = await apiClient.get('pedido/editar',{
                        params:{
                            pedido_id: id,
                            empresa: sessionStorage.getItem('@id_meu_bar')
                        }
                    })

                    if(response.data.length === 0){
                        toast.warn('Não existe itens para editar, cancele o pedido!')
                        navigate('/pedidos')
                        return
                    }
                           
                    let data = {
                        pedido_id: response.data[0].PEDIDO,
                        mesa: response.data[0]?.MESA,
                        nome: response.data[0]?.NOME_CLIENTE,
                        empresa: sessionStorage.getItem('@id_meu_bar'),
                        uuid: response.data[0]?.UUID,
                        totalpedido: response.data[0]?.TOTALPEDIDO
                    }
                    setObsPedido(response.data[0].OBS)
                    localStorage.setItem('@NumMesaBar', JSON.stringify(data))
                    setDadosMesa(data)

                    let itensmesa = [];
                    setItensMesa([])
                    let total:number = 0

                    response.data.map( (item: any) => {
                        total += item?.TOTAL

                        itensmesa.push({
                            codigoprod: item?.CODIGO,
                            nomeprod: item?.NOMEPRODUTO,
                            quant: item?.QUANTIDADE,
                            valor: item?.VALOR_UNITARIO,
                            total: item?.TOTAL,
                            descricao: ''
                        })
                    })

                    setTotalPedido(total)
                    setItensMesa(itensmesa)
                    setBtnDisable(true)
                    setLoading(false)
                } catch {
                    toast.error('Erro buscando dados do pedido!')
                    navigate('/pedidos')
                    setLoading(false)
                }
            }
        }

        getDetailPedido()

    },[])

    async function handleAbrirMesa(e: FormEvent){
        e.preventDefault()

        if(!isNumber(numMesa)){
            toast.error('Digite Nº da mesa!')
            return
        }

        if (parseInt(numMesa) <= 0 || parseInt(numMesa) > 140){
            toast.warn('Nº da mesa inválida!')
            return           
        }

        try{
            let obj = {
                mesa: numMesa,
                nome: nome == '' ? 'CONSUMIDOR' : nome.toUpperCase(),
                empresa: sessionStorage.getItem('@id_meu_bar'),
                uuid: '@SISTEMA-WEB',
                totalpedido: 0
            }

            const apiClient = setupApiClient()
    
            const response = await apiClient.post('/pedidoweb', obj)

            if(response.data?.COD == 201){
                let obj = {
                    mesa: numMesa,
                    nome: nome == '' ? 'CONSUMIDOR' : nome.toUpperCase(),
                    empresa: sessionStorage.getItem('@id_meu_bar'),
                    uuid: '@SISTEMA-WEB',
                    totalpedido: 0,
                    pedido_id: response.data?.ID
                }

                setBtnDisable(true)                
                localStorage.setItem('@NumMesaBar', JSON.stringify(obj))
                setDadosMesa(obj)
                setLoading(false)
                setNumMesa('')
                setNome('')
                toast.success('Mesa '+numMesa+' aberta!')
            } else {
                setBtnDisable(false)
                setLoading(false)
                toast.error(response.data?.COD+' - '+response.data?.MSG)
                return 
            }

        } catch(error) {
            toast.error('Erro abrindo a mesa '+ numMesa)
            setLoading(false)
            console.log(error)
        }
    }

    async function handleAddItens(e: FormEvent){
        e.preventDefault();

        if(valorvenda == ''){
            toast.warn('Digite o valor do produto!')
            return
        }

        if(!isNumber(valorvenda)){
            toast.error('Valor de venda inválido! :(')
            return
        }

        if(parseFloat(valorvenda) <= 0){
            toast.error('Valor venda inválido! :/')
            return            
        }

        if(quant === ''){
            toast.warn('Digite a quantidade!')
            return
        }

        if(!isNumber(quant)){
            toast.error('Quantidade inválida! :(')
            return
        }

        if (parseFloat(quant) === 0 || parseFloat(quant) < 0){
            toast.warn('Quantidade inválida! :/')
            return           
        }

        
        try{
            setLoading(true)
            if(id){
                const dadosPedido = localStorage.getItem('@NumMesaBar')
                const p: MesaProps = JSON.parse(dadosPedido)

                let pedido = {
                    empresa: sessionStorage.getItem('@id_meu_bar'),
                    idmesa: id,
                    mesa: p?.mesa,
                    codigoprod: parseInt(produtos[produtoSelecionado].ID),
                    nomeprod: produtos[produtoSelecionado].NOMEPRODUTO,
                    quant: parseFloat(quant),
                    valor: parseFloat(valorvenda),
                    total: parseFloat(valorvenda) * parseFloat(quant)
                }

                const apiClient = setupApiClient()
    
                const response = await apiClient.put('/pedidoweb', pedido)
                
                if(response.data?.COD == 200){
                    setPedAtualizado(true)
                } else {
                    setLoading(false)
                    setPedAtualizado(false)
                    toast.error(response.data?.COD+' - '+response.data?.MSG)
                    return 
                }
            } else {
                const dadosPedido = localStorage.getItem('@NumMesaBar')
                const p: MesaProps = JSON.parse(dadosPedido)

                let pedido = {
                    empresa: sessionStorage.getItem('@id_meu_bar'),
                    idmesa: p?.pedido_id,
                    mesa: p?.mesa,
                    codigoprod: parseInt(produtos[produtoSelecionado].ID),
                    nomeprod: produtos[produtoSelecionado].NOMEPRODUTO,
                    quant: parseFloat(quant),
                    valor: parseFloat(valorvenda),
                    total: parseFloat(valorvenda) * parseFloat(quant)
                }

                const apiClient = setupApiClient()
    
                const response = await apiClient.put('/pedidoweb', pedido)
                
                if(response.data?.COD == 200){
                    setPedAtualizado(true)
                } else {
                    setLoading(false)
                    setPedAtualizado(false)
                    toast.error(response.data?.COD+' - '+response.data?.MSG)
                    return 
                }                
            }

            const idx = itensMesa.findIndex(item => item.codigoprod == parseInt(produtos[produtoSelecionado].ID))
            
            if(idx > -1){
                if(itensMesa[idx].valor == parseFloat(valorvenda)){
                    itensMesa[idx].quant += parseFloat(quant)
                    itensMesa[idx].total = itensMesa[idx].valor * itensMesa[idx].quant
                } else {
                    let arrCart: ItensMesaProps
                
                    arrCart = {
                        codigoprod: parseInt(produtos[produtoSelecionado].ID),
                        nomeprod: produtos[produtoSelecionado].NOMEPRODUTO,
                        quant: parseFloat(quant),
                        valor: parseFloat(valorvenda),
                        total: parseFloat(valorvenda) * parseFloat(quant)
                    }
                    
                    setItensMesa([...itensMesa, arrCart])
                }    
            } else {
                let arrCart: ItensMesaProps
                
                arrCart = {
                    codigoprod: parseInt(produtos[produtoSelecionado].ID),
                    nomeprod: produtos[produtoSelecionado].NOMEPRODUTO,
                    quant: parseFloat(quant),
                    valor: parseFloat(valorvenda),
                    total: parseFloat(valorvenda) * parseFloat(quant)
                }
                
                setItensMesa([...itensMesa, arrCart])
            }

            setTotalPedido(total => total + parseFloat(valorvenda) * parseFloat(quant))
            setLoading(false)
            setQuant('1')
            setProdutoSelecionado(0)
            setValorVenda(produtos[0].PRECO)
            parseFloat(produtos[0].PRECO) <= 0 ? setdesabilita(false) : setdesabilita(true)

        } catch(error) {
            console.log(error)
            toast.error('Erro adicionando item!')
        }

        setLoading(false)
    }

    function handleProdutoSelecionado(e:any){
        setProdutoSelecionado(e.target.value)
        setValorVenda(produtos[e.target.value].PRECO)
        parseFloat(produtos[e.target.value].PRECO) <= 0 ? setdesabilita(false) : setdesabilita(true)

    }

    function handleCancelaItem(item: ItensMesaProps){
        if(itensMesa.length <= 1){
            toast.warn('Cancele o pedido!')
            return
        }
        
        setItemDelete({
            ID: String(item?.codigoprod),
            CATEGORIA: item?.nomeprod
        })

        setCancelPedido(false)    
        setOpenModal(true)        
    }

    async function handleReqCancelaItem(id_produto: string){
        try{
            let pedido = 0
            const dadosPedido = localStorage.getItem('@NumMesaBar')
            if(dadosPedido){
                const p: MesaProps = JSON.parse(dadosPedido)
                pedido = p?.pedido_id
            }

            const apiClient = setupApiClient()

            const response = await apiClient.delete('/pedido/item',{
                params:{
                    empresa: sessionStorage.getItem('@id_meu_bar'),
                    pedido_id: id ? id : pedido,
                    id_item: id_produto
                }
            })
            
            if(response.data?.COD == 200){
                setPedAtualizado(true)
                toast.success('Item cancelado!')
                const retvalor = itensMesa.filter(item => item?.codigoprod == parseInt(id_produto))
                const newArr = itensMesa.filter(item => item?.codigoprod !== parseInt(id_produto))
                setItensMesa(newArr)
                setTotalPedido(totalPedido - retvalor[0]?.total)
                setOpenModal(false)
                setLoading(false)
            } else {
                setLoading(false)
                setPedAtualizado(false)
                toast.error(response.data?.COD+' - '+response.data?.MSG)
                return 
            }                
        } catch{
            toast.error('Erro cancelando o item!')
            setLoading(false)
            setOpenModal(false)
        }
    }

    async function handleConcluirPedido(){
        try{
            if(itensMesa.length <= 0){
                toast.warning('Não existe itens na mesa!')
                return                    
            }

            if(id){
                const dadosPedido = localStorage.getItem('@NumMesaBar')
                if(!dadosPedido){
                    toast.warning('Não existe mesa aberta!')
                    return
                }

                if(pedAtualizado){
                    const dadosPedido = localStorage.getItem('@NumMesaBar')
                    if(!dadosPedido){
                        toast.warning('Não existe mesa aberta!')
                        return
                    } 
                    
                    const p: MesaProps = JSON.parse(dadosPedido)
    
                    let objConcluir = {
                        mesa: p?.mesa,
                        pedido_id: p?.pedido_id,
                        empresa: sessionStorage.getItem('@id_meu_bar'),
                        obs: obspedido
                    }
    
                    const apiClient = setupApiClient()
        
                    const response = await apiClient.put('/addobs', objConcluir)              

                    if(response.data?.COD == 200){
                        toast.success('Pedido atualizado!')
                        navigate('/pedidos')
                    } else {
                    setLoading(false)
                    toast.error(response.data?.COD+' - '+response.data?.MSG)
                    return 
                }

                } else {
                    toast.warn('Ops, você não editou o pedido apenas retorne!')
                    return   
                }
            } else {
                const dadosPedido = localStorage.getItem('@NumMesaBar')
                if(!dadosPedido){
                    toast.warning('Não existe mesa aberta!')
                    return
                } 
                
                const p: MesaProps = JSON.parse(dadosPedido)

                let objConcluir = {
                    mesa: p?.mesa,
                    pedido_id: p?.pedido_id,
                    empresa: sessionStorage.getItem('@id_meu_bar'),
                    obs: obspedido
                }

                const apiClient = setupApiClient()
    
                const response = await apiClient.put('/concluirpedidoweb', objConcluir)
            
                if(response.data?.COD == 200){
                    toast.success('Pedido concluido!')
                    navigate('/pedidos')
                } else {
                    setLoading(false)
                    toast.error(response.data?.COD+' - '+response.data?.MSG)
                    return 
                }
            }
        } catch(e) {
            console.log(e)
            toast.error('Erro finalizando o pedido!')
        }
    }

    function handleCancelarPedido(){
        setItemDelete({
            ID: String(dadosMesa?.mesa),
            CATEGORIA: 'Mesa ' + String(dadosMesa?.mesa) + ' - '+ dadosMesa?.nome
        })

        setCancelPedido(true)        
        setOpenModal(true)
    }

    async function handleReqCancelarPedido(){
        try{
            setLoading(true)
            let pedido = 0
            const dadosPedido = localStorage.getItem('@NumMesaBar')
            if(dadosPedido){
                const p: MesaProps = JSON.parse(dadosPedido)
                pedido = p?.pedido_id
            }

            const apiClient = setupApiClient();

            const response = await apiClient.delete('/pedido',{
                params:{
                    pedido_id: id ? id : pedido,
                    empresa: sessionStorage.getItem('@id_meu_bar')
                }
            })

            if(response.data?.COD == 200){
                setLoading(false)
                setDadosMesa(null)
                setItensMesa([])
                setTotalPedido(0)
                setOpenModal(false)
                toast.success('Pedido cancelado!')
                navigate('/pedidos')
            } else {
                setLoading(false)
                toast.error(response.data?.COD+' - '+response.data?.MSG)
                return 
            }
           } catch(e) {
            console.log(e)
            toast.error('Erro cancelando pedido!')
            setLoading(false)            
            setOpenModal(false)
        }
    }

    function handleObs(value: string){
        setObsPedido(value)
        setPedAtualizado(true)
    }

    function handleRetornar(){
        if(id && !pedAtualizado){
            navigate('/pedidos')
        } else {
            if(dadosMesa){
                handleCancelarPedido()
            } else {
                navigate('/pedidos')
            } 
        }
    }

    function handleCategoriaSelecionada(e:any){
        setCategoriaSelecionada(e.target.value)
        setValorVenda('0')
    }

    if(loading){
        return(
            <main>
                <Header
                    menu={1}
                />
                <Loading/>
                <Footer/>
            </main>
        )
    }

    return(
        <main>
            <Header
                menu={1}
            /> 
            <section className='sec-container-novopedido'>
                <div className='div-content-novopedido'>

                    <div className='div-header-novopedido'>
                        <div className='div-titulo-pedido'>
                            <button title='VOLTAR' onClick={handleRetornar}>
                                <IoMdArrowBack size={30} color='#FFF'/>
                            </button>
                            <span>{btnDisable && id ? `EDITANDO O PEDIDO ${id}` : 'Novo Pedido'}</span>
                        </div>

                        <form className='form-novo-pedido' onSubmit={handleAbrirMesa}>
                            <Input
                                id='mesa'
                                type='text'
                                placeholder='Mesa Nº'
                                value={numMesa}
                                onChange={ (e) => setNumMesa(e.target.value)}
                                disabled={btnDisable}
                            />

                            <Input
                                id='nome'
                                type='text'
                                placeholder='Nome "Opcional"'
                                value={nome}
                                onChange={ (e) => setNome(e.target.value)}
                                disabled={btnDisable}
                            />
                            <div>
                                <button type="submit" disabled={btnDisable} style={ { cursor: btnDisable ? 'not-allowed' : 'pointer' } }>                     
                                    ABRIR MESA
                                </button> 
                            </div>
                        </form>

                        <div className='mesaAberta'>
                            <h3>MESA { dadosMesa?.mesa ? `${dadosMesa?.mesa} ABERTA` : ''} {dadosMesa?.nome ? ` - ${dadosMesa?.nome}` : ''}</h3>
                            <h5>{`TOTAL: ${totalPedido.toLocaleString('pt-BR',{style:'currency', currency:'BRL'})}`}</h5>    
                        </div>

                        <div className='div-itenspedido'>
                            <h2>ADICIONAR ITENS</h2>
                            <div className='div-selectitens'>
                                <div className='div-cat'>
                                    <label htmlFor="idcateg">CATEGORIA</label>
                                    <select value={categoriaSelecionada} onChange={handleCategoriaSelecionada}>
                                    { categorias.length > 0 && categorias.map( (item: CategoriaProps, index: any) => {
                                        return(
                                            <option key={item?.ID} value={index}>
                                                {item?.CATEGORIA}
                                            </option>
                                        )
                                    })}
                                    </select>

                                </div>
                                    
                                <div className='div-prod'>
                                    <label htmlFor="idprodut">PRODUTO</label>
                                    <select value={produtoSelecionado} onChange={handleProdutoSelecionado}>
                                    { produtos.length > 0 && produtos.map( (item: ProductProps, index: any) => {
                                        return(
                                            <option key={item?.ID} value={index}>
                                                {item?.NOMEPRODUTO} - R$ {parseFloat(item?.PRECO).toFixed(2)}
                                            </option>
                                        )
                                    })}
                                    </select>
                                </div>
                            </div>

                            <form className='form-addquantidade' onSubmit={handleAddItens}>
                                <div className='div-form-quant'>
                                    <label htmlFor="idvalor">VALOR</label>
                                    <Input
                                        id='idvalor'
                                        type='text'
                                        placeholder="Valor"
                                        value={valorvenda}
                                        onChange={ (e) => setValorVenda(e.target.value)}
                                    />
                                </div>

                                <div className="div-form-quant">
                                    <label htmlFor="idquant">QUANTIDADE</label>
                                    <Input
                                        id='idquant'
                                        type='text'
                                        placeholder="Quantidade"
                                        value={quant}
                                        onChange={ (e) => setQuant(e.target.value)}
                                    />
                                </div>                            

                                <div className='div-btn-form'>
                                    <button
                                        type='submit'
                                        disabled={!btnDisable} 
                                        style={ { cursor: !btnDisable ? 'not-allowed' : 'pointer' } } 
                                    >
                                        <IoMdAdd size={20} color='#FFF'/>ADICIONAR ITEM
                                    </button>
                                </div>
                            </form>                         
                        
                            <div className="div-ped-obs">
                                <label htmlFor="obs-txt-ped">OBSERVAÇÕES</label>
                                <textarea 
                                    name="obs-txt-ped"
                                    value={obspedido}
                                    placeholder='OBSERVAÇÕES'
                                    onChange={(e) => handleObs(e.target.value)}
                                />
                            </div>

                            { itensMesa.length > 0 && (
                                <div className='btnConcluir'>
                                    <Button id='ok' onClick={handleConcluirPedido}>
                                        <MdSend size={20} color='#FFF'/>
                                        CONCLUIR PEDIDO
                                    </Button>

                                    <Button id='cancel' onClick={handleCancelarPedido}>
                                        <MdCancel size={23} color='#FFF'/>
                                        CANCELAR PEDIDO
                                    </Button>
                                </div>
                            )}                           
                        </div>                       
                    </div>

                    <div className='div-header-itenspedido'>
                        <div className='table_itens'>
                            { itensMesa.length <= 0 ? (
                            <div className='emptyItens'>
                                <span>Não existe itens nesse pedido!</span>
                            </div>
                            ) : (  
                            <table>
                                <thead>
                                    <tr className='header'>
                                        <th className='it1' scope="col">DESCRIÇÃO</th>
                                        <th className='it2' scope="col">QUANT</th>
                                        <th className='it3' scope="col">VALOR</th>
                                        <th className='it4' scope="col">TOTAL</th>
                                        <th className='it5' scope="col">AÇÃO</th>                                    
                                    </tr>
                                </thead>
                                <tbody>
                                { itensMesa.map( (item: ItensMesaProps, index: any) => {
                                    return(
                                        <tr key={index}>
                                            <td data-label="Descricao">{item?.nomeprod}</td>
                                            <td data-label="Quantidade" style={ {textAlign:'right'} }>{item?.quant.toFixed(2)}</td>
                                            <td data-label="Preco" style={ {textAlign:'right'} } >{item?.valor.toLocaleString('pt-BR',{style:'currency', currency:'BRL'})}</td>
                                            <td data-label="Total" style={ {textAlign:'right'} } >{item?.total.toLocaleString('pt-BR',{style:'currency', currency:'BRL'})}</td>
                                            <td data-lable="Acoes">
                                            <div className='btncancelaitem'>
                                                <button title="CANCELAR ITEM" onClick={ () => handleCancelaItem(item)}>
                                                    <FaTrash size={20} color='#F21A22'/>
                                                </button>
                                            </div>
                                            </td>
                                        </tr>
                                    )
                                })}
                                </tbody>
                            </table>                    
                            )}
                        </div>
                    </div>
                </div>

                { openModal && (
                    <Modal
                        titulo= {cancelPedido ? 'CANCELAR PEDIDO?' : 'CANCELAR O ITEM?'}
                        titulobtn={cancelPedido ? 'SIM - CANCELAR' : 'SIM - RETIRAR'}
                        descricao={itemDelete}
                        onClose={ () => setOpenModal(false)}
                        onRequest={ (id_produto) =>  cancelPedido ? handleReqCancelarPedido() : handleReqCancelaItem(id_produto)}
                    />
                )}

            </section>
            <Footer/>
        </main>
    )
}