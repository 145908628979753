import { useState } from 'react'
import './index.css'
import { TfiEmail } from 'react-icons/tfi'
import { BsTelephone } from 'react-icons/bs'
import { IoLocationOutline } from 'react-icons/io5'
import { GrFormNextLink } from 'react-icons/gr'
import { Link } from 'react-router-dom'
import { BiTime } from 'react-icons/bi'
import { emailTest } from '../../../utils/utils'
import { setupApiClient } from '../../../services/api'
import { toast } from 'react-toastify'
import AnchorLink from 'react-anchor-link-smooth-scroll'

export default function FooterHome(){
    return(
        <footer className='footer-container'>
            <section className='containter-footer'>
                <div className='footer-section'>
                    <section className='section1'>
                        <h3>Contato</h3>
                        <span><TfiEmail color='#FFF' size={16}/>e-mail: contato@wgainformatica.com.br</span>
                        <span><BsTelephone color='#FFF' size={16}/>Telefone: +55 (19) 3546-5686</span>
                        <span><IoLocationOutline color='#FFF' size={18}/>Rua visconde do rio branco, 181<br/>Sala 2 - Centro<br/>Cordeirópolis/SP - Brasil</span>
                    </section>

                    <section className='section2'>
                        <h3>Links úteis</h3>
                        <Link to='/'>HOME</Link>
                        <Link to='/login' target='_blank'>ACESSAR</Link>
                        <Link to='/cadastro/0' target='_blank'>TESTE GRÁTIS</Link>
                        <Link to='/quemsomos'>QUEM SOMOS</Link>
                        <AnchorLink href='#planos'>PLANOS</AnchorLink>
                        <AnchorLink href='#contato'>CONTATO</AnchorLink>
                    </section>

                    <section className='section3'>
                        <h3>Informações</h3>
                        <span id='info'><BiTime color='#FFF' size={18} />Horário de atendimento e suporte</span>
                        <span>Segunda a Sexta-Feira das 08:00 às 18:00 horas</span>
                        
                        <span>Horário de Brasília - Dias úteis</span>
                    </section>
                </div>
                <div className='footer-info'>
                    <span>COPYRIGHT © {new Date().getFullYear()} <Link to='https://site.wgainformatica.com.br/' target='_blank'>WGA Informática®</Link> - Todos os direitos reservados - Software para comércio em geral - Atendemos todo Brasil</span>
                </div>
            </section>
        </footer>
    )
}