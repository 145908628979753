import './index.css'
import HeaderHome from '../../components/Home/Header'
import MenuMobile from '../../components/Home/MenuMobile'
import soft from '../../assets/software.png'
import Contato from '../../components/Home/Contato'
import FooterHome from '../../components/Home/Rodape'

export default function QuemSomos(){

    const ano = new Date().getFullYear() - 2008;

    function handletoggle(){
        const nav = document.querySelector('.menumobile-box')
        nav.classList.toggle('activemenu')   
    }

    return(
        <>
            <HeaderHome
                onOpenMenu={handletoggle}
            />
            <div className='div-quemsomos'>
                <section className='quemsomos-container'>
                    <div>
                        <img src={soft} alt="Software" />
                    </div>
                    <div>
                        <p>Somos uma empresa que foi criada especialmente para prestação de serviços nas áreas de desenvolvimento de software comercial, sites e aplicativos para pessoa física e jurídica.</p>
                        <p>Estamos atuando no mercado de informática há mais de {ano} anos e já contamos com grande número de clientes no segmento de oficinas mecânicas, lojas de auto peças, materiais de construção, papelaria, transportadoras, Bares e restaurantes, Clínicas e muito mais.</p>
                    </div>
                </section>
            </div>
            <Contato/>

            <FooterHome/> 

            <MenuMobile
                openMenu={handletoggle}   
            />
        </>
    )
}