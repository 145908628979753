import './index.css'
import { Link } from 'react-router-dom'
import { IoIosRocket, IoMdCloseCircle } from "react-icons/io"
import { FaRegCheckCircle } from "react-icons/fa"
import { HiHandThumbUp, HiHandThumbDown } from "react-icons/hi2"

export default function Planos(){
    return(
        <section className='containerplanos' id='planos'>
            <div className='div-container-planos'>

                <div className='planos-texto-container'>
                    <h1>Preços que cabem no seu bolso</h1>
                    <h3>Experimente grátis por 7 dias, não é necessário inserir cartão de crédito.</h3>
                    <h4>Um software web que está sendo atualizado constantemente por nossa equipe de desenvolvedores para facilitar o seu uso no dia a dia.</h4>
                </div>

                <section className='div-planos-valores'>
                    <div className='div-plano1'>
                        <div className='plano-mod3'>
                            <h2>Módulo básico</h2>
                            <IoIosRocket size={50} color='#737373'/>
                        </div>
                        <div className='plano-valores'>
                            <div className='plano-div-valores'>
                                <span className='spanvalor'>R$ 99,90</span>
                                <span className='spanmes'> por mês</span>
                            </div>
                            <Link to='/cadastro/0' target='_blank'>
                                TESTAR GRÁTIS
                            </Link>
                            <div className='planos-txtbtn'>
                                <span>Teste grátis pelo seu computador.</span>   
                            </div> 
                            <ul>
                                <li>
                                    <HiHandThumbUp color='green' size={25} />
                                    <span>Cadastros ilimitados</span>
                                </li>
                                <li>
                                    <HiHandThumbUp color='green' size={25}/>
                                    <span>Pedidos ilimitados</span>
                                </li>
                                <li>
                                    <HiHandThumbUp color='green' size={25}/>
                                    <span>Controle de mesas abertas e ocupadas</span>
                                </li>
                                <li>
                                    <HiHandThumbUp color='green' size={25}/>
                                    <span>Abertura e fechamento de caixa</span>
                                </li>
                                <li>
                                    <HiHandThumbUp color='green' size={25}/>
                                    <span>Pedidos somente via sistema web</span>
                                </li>
                                <li>
                                    <HiHandThumbUp color='green' size={25}/>
                                    <span>Cardápio personalizado com QR Code</span>
                                </li>
                                <li>
                                    <HiHandThumbDown color='#B50003' size={25}/>
                                    <span>Pedidos via aplicativo pelo garçom</span>
                                </li>
                                <li>
                                    <HiHandThumbDown color='#B50003' size={25}/>
                                    <span>Emissão de cupom fiscal S@T</span>
                                </li>
                                <li>
                                    <HiHandThumbDown color='#B50003' size={25}/>
                                    <span>Pedidos via QR Code pelo cliente</span>
                                </li>
                                <li>
                                    <HiHandThumbDown color='#B50003' size={25}/>
                                    <span>Emissão de NFe "DANFE"</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className='div-plano2'>
                        <div className='plano-mod3'>
                            <h2>Módulo controle</h2>
                            <IoIosRocket size={50} color='#737373'/>
                        </div>
                        <div className='plano-valores'>
                            <div className='plano-div-valores'>
                                <span className='spanvalor'>R$ 149,90</span>
                                <span className='spanmes'> por mês</span>
                            </div>
                            <Link to='/cadastro/0' target='_blank'>
                                TESTAR GRÁTIS
                            </Link>
                            <div className='planos-txtbtn'>
                                <span>Teste grátis pelo seu computador.</span>   
                            </div> 
                            <ul>
                                <li>
                                    <HiHandThumbUp color='green' size={25} />
                                    <span>Cadastros ilimitados</span>
                                </li>
                                <li>
                                    <HiHandThumbUp color='green' size={25}/>
                                    <span>Pedidos ilimitados</span>
                                </li>
                                <li>
                                    <HiHandThumbUp color='green' size={25}/>
                                    <span>Controle de mesas abertas e ocupadas</span>
                                </li>
                                <li>
                                    <HiHandThumbUp color='green' size={25}/>
                                    <span>Abertura e fechamento de caixa</span>
                                </li>
                                <li>
                                    <HiHandThumbUp color='green' size={25}/>
                                    <span>Pedidos via sistema web</span>
                                </li>
                                <li>
                                    <HiHandThumbUp color='green' size={25}/>
                                    <span>Cardápio personalizado com QR Code</span>
                                </li>
                                <li>
                                    <HiHandThumbUp color='green' size={25}/>
                                    <span>Pedidos via aplicativo pelo garçom</span>
                                </li>
                                <li>
                                    <HiHandThumbUp color='green' size={25}/>
                                    <span>Emissão de cupom fiscal S@T</span>
                                </li>
                                <li>
                                    <HiHandThumbDown color='#B50003' size={25}/>
                                    <span>Pedidos via QR Code pelo cliente</span>
                                </li>
                                <li>
                                    <HiHandThumbDown color='#B50003' size={25}/>
                                    <span>Emissão de NFe "DANFE"</span>
                                </li>
                            </ul>
                        </div>
                    </div> 
                    <div className='div-plano3'>
                        <div className='plano-mod3'>
                            <h2>Módulo avançado</h2>
                            <IoIosRocket size={50} color='#575757'/>
                        </div>
                        <div className='plano-valores'>
                            <div className='plano-div-valores'>
                                <span className='spanvalor3'>R$ 199,90</span>
                                <span className='spanmes3'> por mês</span>
                            </div>
                            <Link to='/cadastro/0' target='_blank'>
                                TESTAR GRÁTIS
                            </Link>
                            <div className='planos-txtbtn'>
                                <span>Teste grátis pelo seu computador.</span>   
                            </div> 
                            <ul className='ul-mod3'>
                                <li>
                                    <HiHandThumbUp color='green' size={25} />
                                    <span>Cadastros ilimitados</span>
                                </li>
                                <li>
                                    <HiHandThumbUp color='green' size={25}/>
                                    <span>Pedidos ilimitados</span>
                                </li>
                                <li>
                                    <HiHandThumbUp color='green' size={25}/>
                                    <span>Controle de mesas abertas e ocupadas</span>
                                </li>
                                <li>
                                    <HiHandThumbUp color='green' size={25}/>
                                    <span>Abertura e fechamento de caixa</span>
                                </li>
                                <li>
                                    <HiHandThumbUp color='green' size={25}/>
                                    <span>Pedidos via sistema web</span>
                                </li>
                                <li>
                                    <HiHandThumbUp color='green' size={25}/>
                                    <span>Cardápio personalizado com QR Code</span>
                                </li>
                                <li>
                                    <HiHandThumbUp color='green' size={25}/>
                                    <span>Pedidos via aplicativo pelo garçom</span>
                                </li>
                                <li>
                                    <HiHandThumbUp color='green' size={25}/>
                                    <span>Emissão de cupom fiscal S@T</span>
                                </li>
                                <li>
                                    <HiHandThumbUp color='green' size={25}/>
                                    <span>Pedidos via QR Code pelo cliente</span>
                                </li>
                                <li>
                                    <HiHandThumbUp color='green' size={25}/>
                                    <span>Emissão de NFe "DANFE"</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </section>
            </div>
        </section>
    )
}