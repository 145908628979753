import { Link } from "react-router-dom"
import './index.css'
import AnchorLink from 'react-anchor-link-smooth-scroll'
import { AiFillHome, AiFillPhone } from 'react-icons/ai'
import { FaFlag, FaUserPlus } from 'react-icons/fa'
import { ImPriceTags } from 'react-icons/im'
import { IoCloseCircle } from 'react-icons/io5'

interface MenuMobileProps{
    openMenu: () => void;
}

export default function MenuMobile({ openMenu }: MenuMobileProps){
    return(
        <nav className='menumobile-box'>
            <div className='btn-close'>
                <span>MENU</span>
                <button onClick={openMenu}>
                    <IoCloseCircle size={40} color='#F21A22' /> 
                </button>
            </div>
            <ul>
                <li>
                    <AiFillHome color="#F21A22" size={20}/> 
                    <Link to='/' onClick={openMenu}>
                        HOME
                    </Link> 
                </li>
                <li>
                    <FaUserPlus color="#F21A22" size={20}/> 
                    <span>
                        TESTE GRÁTIS PELO COMPUTADOR
                    </span> 
                </li>
                <li>
                    <ImPriceTags color="#F21A22" size={20}/>
                    <AnchorLink href='#planos' onClick={openMenu}>
                        PLANOS
                    </AnchorLink> 
                </li>
                <li>
                    <FaFlag color="#F21A22" size={20}/>
                    <Link to='/quemsomos' onClick={openMenu} >QUEM SOMOS</Link>
                </li>
                <li>
                    <AiFillPhone color="#F21A22" size={20}/>
                    <AnchorLink href='#contato' onClick={openMenu}>
                        CONTATO
                    </AnchorLink> 
                </li>
            </ul>
        </nav>
    )
}